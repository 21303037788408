import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import api from './config/axios.js';
import axios from 'axios';



const app = createApp(App).use(store).use(router).use(vuetify).use(ElementPlus);

app.config.globalProperties.$axios= axios;
app.config.globalProperties.$api= api;
app.component('VueDatePicker', VueDatePicker);

app.mount("#app");
