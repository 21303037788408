// Vuetify
import { createVuetify } from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import colors from "vuetify/lib/util/colors";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import es from "vuetify/lib/locale/es";

const myTheme = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    urface: "#FFFFFF",
    primary: "#090909",
    "primary-dark": "#2c64ac",
    "primary-accent": "#090909",
    secondary: "#34A693",
    accent: "#82B1FF",
    error: "#FF5252",
    info: "#2196F3",
    success: "#13CE66",
    warning: "#ffc107",
    warning2: "#a4a43e",
    gris: "#757575",
    orange: "#E37106",
    pink: "#FE92F6",
    "gris-claro": "#E0E0E0",
    primaryDk1: colors.teal.darken1,
    greenDivider: colors.teal.darken1,
    greenBg: colors.teal.lighten5,
  },
};

export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "myTheme",
    themes: {
      myTheme,
    },
  },
  locale: {
    locale: "es",
    fallback: "es",
    messages: { es },
  },
  icons: {
    defaultSet: "mdi",
  },
});
