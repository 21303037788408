<template>
  <v-navigation-drawer
    v-model="drawer"
    :rail="rail"
    permanent
    width="225"
    elevation="4"
  >
    <v-list-item nav>
      <v-img
        height="50"
        class="mx-auto"
        :src="require('@/assets/logos/Logo.png')"
      />
    </v-list-item>

    <v-divider></v-divider>
    <v-list
      density="compact"
      nav
      color="primary"
      lines="one"
      exact
      :disabled="user.cambio_clave"
    >
      <v-list-item v-for="(item, i) in items" :key="item.to"
        :to="item.to"
        exact
      >
        <template v-slot:prepend>
          <v-icon
            :icon="item.icon"
            color="primary-accent"
            :id="'iconMenu-' + i"
          ></v-icon>
          <v-tooltip
            v-if="rail"
            :activator="'#iconMenu-' + i"
            location="right"
            :text="item.title"
          />
        </template>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-2" v-if="!rail">
        <v-btn color="error" block @click="logout">
          <v-icon start>mdi-logout</v-icon>
          Cerrar Sesión
        </v-btn>
      </div>
      <div v-else>
        <v-list density="compact" nav color="primary" lines="one">
          <v-list-item @click="logout">
            <template v-slot:prepend>
              <v-icon
                style="opacity: 1 !important"
                icon="mdi-logout"
                color="error"
                id="iconMenu-logout"
              ></v-icon>
              <v-tooltip
                activator="#iconMenu-logout"
                location="right"
                text="Cerrar Sesión"
              />
            </template>
          </v-list-item>
        </v-list>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import menuItem from "@/assets/json/menu.json";

export default {
  data() {
    return {
      drawer: true
    };
  },
  methods: {
    ...mapActions(["switchRail", "logout"]),
    acceso(require) {
      if (!require) return true;
      return this.user.permissions.includes(require);
    }
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["getRail"]),
    items() {
      return menuItem.filter(
        (item) => !item.hide_lat && this.acceso(item.require)
      );
    },
    rail: {
      get() {
        return this.getRail;
      },
      set(state) {
        this.switchRail(state);
        return state;
      }
    }
  },
};
</script>