import axios from "axios";
import store from "../store";
import { ElMessage } from "element-plus";

const baseUrl = process.env.VUE_APP_API_URL;
const api = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
});
api.interceptors.request.use((config) => {
    const token = store.state.token;
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log("error: ", error);
      if (error.response.status !== 429) {
        ElMessage({
          message: error.response.data.message,
          type: "error",
          grouping: true,
          appendTo: document.getElementById("#dialogoEdicion") || document.body
        });
      } else {
        ElMessage({
          message: error.response.data,
          type: "error",
          grouping: true
        });
      }
  
      if (error.response.status === 401) {
        store.dispatch("logout", true);
      }
      return Promise.reject(error);
    }
);

export default api;