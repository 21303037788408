import api from "@/config/axios.js";
const basePath = "/personal/";

export default{
    obtenerPersonal(){
        return api.get(basePath);
    },
    obtenerPersonalActivo(){
        return api.get(basePath+"active");
    },
    agregarPersonal(body){
        return api.post(basePath, body);
    },
    actualizarPersonal(body, id){
        return api.put(basePath+id, body);
    },
    deletePersonal(id){
        return api.delete(basePath+id);
    }
}