<template>
    <v-dialog
        :model-value="dialog"
        width="800"
        @update:model-value="cerrar()"
        id="dialogoEdicion"
    >
        <v-card>
            <v-toolbar color="primary-accent" density="compact">
                <v-toolbar-title class="text-center">
                <span class="titulo-dialogo raleway-light">
                    Personal de Trabajo
                </span>
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-row v-loading="loading" no-gutters justify="center" align="center">
                    <v-col cols="6" sm="3" md="3" v-for="(member, i) in personals" :key="i" class="pa-2">
                        <el-image
                            class="align-right"
                            style="height: 150px; width: 150px"
                            :src="imageSrc(member.image)"
                            :preview-src-list="prevImgs"
                            fit="contain"
                        />
                        <div class="text-center mt-2">
                            <div class="lato textName">{{ member.name }} {{ member.f_lastname }}</div>
                            <div class="lato" >{{ member.cargo }}</div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pa-4">
                <v-btn color="error" variant="outlined" elevation="2" @click="cerrar()">
                    Salir
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
  import personal from "@/services/personal.js"


export default{
    name: "DialogoEdicion",
    emits: ["cerrar"],
    props:{
        dialog: Boolean
    },
    data(){
        return{
            personals: [],
            loading: true,
        }
    },
    computed:{
        prevImgs() {
            return this.personals.map((member) => this.imageSrc(member.image));
        },
    },
    methods:{
        cerrar(){
            this.$emit("cerrar");
        },
        async getPersonal(){
            this.loading=true;
            await personal.obtenerPersonalActivo()
            .then((res) =>{
                    this.personals = res.data;
                })
            .catch((err) => err);
            this.loading=false;
        },
        imageSrc(image) {
            return "data:" + image.dataType + ";base64," + image.base64;
        },
    },
    mounted(){
        this.getPersonal();
    }

}

</script>

<style scoped>
.textName {
  font-weight: 400;
  font-size: 20px;
}
.align-right {
    margin-left: 15px;  
}

</style>