import { createStore } from "vuex";
import auth from "@/services/auth";
import { ElMessage } from "element-plus";
import router from "@/router";
import moment from "moment";


export default createStore({
    state:{
        rail: false,
        token: null,
        expires: null,
        expireTimeOut: null,
        user: {},
        turno: localStorage.getItem('turno') || 'AB',
    },
    getters: {
        getRail: (state) => state.rail,
    },
    mutations: {
        SET_TURNO(state, nuevoTurno){
            state.turno = nuevoTurno;
            localStorage.setItem('turno', nuevoTurno);
        },
        SET_RAIL(state){
            state.rail = !state.rail;
        },
        SET_TOKEN(state, token){
            state.token = token;
        },
        SET_EXPIRES(state, expires) {
            state.expires = expires ? expires * 1000 : null;
            if (process.env.NODE_ENV === "development" && expires) {
              console.log(
                "expiracion",
                moment(state.expires).format("DD/MM/YYYY HH:mm:ss")
              );
            }
        },
        SET_USER(state, user) {
            let permissions = user.permissions.map((item) => item.id_name);
            user.permissions = permissions;
            state.user = user;
        },
        CLEAN_USER(state) {
            state.user = {};
        },
        SET_EXPIRE_TIMEOUT(state) {
            let sesionValida = state.expires > Date.now();
            let vm = this;
        
            if (sesionValida) {
                state.expireTimeOut = setTimeout(() => {
                alert(
                    "Su sesión ha expirado, si quiere seguir utilizando el portal ingrese nuevamente"
                );
                vm.dispatch("logout");
                }, state.expires - Date.now());
            }
        },
        CLOSE_EXPIRE_TIMEUOT(state) {
            process.env.NODE_ENV === "development" && console.log("close timeout");
            clearTimeout(state.expireTimeOut);
            state.expireTimeOut = null;
        },
    },
    actions: {
        switchRail({ commit }){
            commit("SET_RAIL");
        },
        async setRefreshToken({ commit }){
            await auth.refresh()
            .then((res) => {
                if (res.data.status == 1) {
                  commit("SET_TOKEN", res.data.token);
                  commit("SET_EXPIRES", res.data.exp);
                  commit("SET_USER", res.data.user);
                  commit("SET_EXPIRE_TIMEOUT");
                }
              })
              .catch((err) => err);
        },
        setLoginToken({ commit }, payload) {
            commit("SET_TOKEN", payload.token);
            commit("SET_EXPIRES", payload.exp);
            commit("SET_USER", payload.user);
            commit("SET_EXPIRE_TIMEOUT");
        },
        async logout({ commit }, expire) {
            await auth
                .logout()
                .then(async (res) => {
                    if (!expire) {
                    ElMessage({
                        showClose: true,
                        type: "success",
                        message: res.data.message,
                    });
                    }
                    commit("SET_TOKEN", null);
                    commit("SET_EXPIRES", null);
                    commit("CLOSE_EXPIRE_TIMEUOT");
                    await router.push({ name: "login" });
                    commit("CLEAN_USER");
                })
                .catch((err) => err);
        },
        async resetAuth({ commit }) {
            commit("SET_TOKEN", null);
            commit("SET_EXPIRES", null);
            commit("CLOSE_EXPIRE_TIMEUOT");
            await router.push({ name: "login" });
            commit("CLEAN_USER");
        },
    },
    modules: {},
})