import api from "@/config/axios.js";
import Cookies from "js-cookie";
const basePath = "/auth/";

export default{
    login(credentials){
        return api.post(basePath + "login", credentials);
    },
    refresh(){
        return api.get(basePath + "refresh");
    },
    logout(){
        return api.get(basePath + "logout");
    },
    isRefreshToken(){
        return Cookies.get("token");
    },
    cambiarClave(credentials) {
        return api.put(basePath + "changePassword/", credentials);
    }
};